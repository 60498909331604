<template>
  <div
  >
    <v-list shaped>
      <v-list-item-group
          v-model="selectedItem"
          color="primary"
      >
        <v-list-item
            v-for="(item, i) in items"
            :key="i" :link="true" :to="`/${item.url}`"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "AsideMenu",
  data: () => ({
    selectedItem: 1,
  }),
  computed: {
    items: () => [
      {title: 'Contacts', icon: 'mdi-account-group', url: '/contacts'},
      {title: 'Domains', icon: 'mdi-web', url: '/domains'},
      {title: 'Broadcasts', icon: 'mdi-bullhorn-outline', url: '/broadcasts'},
    ]
  },
}
</script>

<style scoped>

</style>