<template>
  <v-app-bar relative color="#6A76AB" dark>
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
      ></v-img>
    </template>

    <v-app-bar-title>{{ pageName }}</v-app-bar-title>
    <Clock />

    <v-spacer></v-spacer>

    <v-btn
      v-for="(action, index) in settings.actions"
      :key="`header-action-${index}`"
      @click="action.onClick"
      :title="action.title"
      icon
    >
      <v-icon>{{ action.icon }}</v-icon>
    </v-btn>

    <v-list
      rounded
      color="transparent"
      dense
      class="p-0 d-flex align-items-center"
      style="height: 48px"
    >
      <v-list-item-group
        v-model="selectedItem"
        color="grey lighten-4"
        class="d-flex"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :link="true"
          :to="item.url"
          class="mb-0"
          @click="pageName = item.title"
        >
          <template>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
        <v-list-item>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex">
                <v-list-item-icon>
                  <v-icon v-text="'mdi-file-import'"></v-icon>
                </v-list-item-icon>
                <v-list-item-title dark v-text="'Files'"></v-list-item-title>
              </div>
            </template>
            <v-list>
              <v-list-item
                :link="true"
                :to="link.url"
                @click="pageName = link.title"
                v-for="(link, index) in files_items"
                :key="index"
              >
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import Clock from "../components/ClockComponent.vue";
export default {
  name: "HeaderComponent",
  props: { toggleDrawer: Function },
  components: {
    Clock,
  },
  data: () => ({
    selectedItem: 1,
    pageName: "",
  }),
  mounted() {
    this.changePageName();
  },
  methods: {
    changePageName() {
      this.pageName = [
        ...this.beta_items,
        ...this.items,
        ...this.files_items,
      ].find((item) => item.url === this.$route.path).title;
    },
  },
  watch: {
    "$route.fullPath": function (newPath, oldPath) {
      this.changePageName();
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.layout.header,
    }),
    items: () => [
      {
        title: "Contacts",
        icon: "mdi-account-group",
        url: "/contacts",
      },
      {
        title: "Broadcasts",
        icon: "mdi-bullhorn-outline",
        url: "/broadcasts",
      },
      { title: "Domains", icon: "mdi-web", url: "/domains" },
    ],
    files_items() {
      return [
        {
          title: "Imported files",
          url: "/imported-files",
        },
        {
          title: "Exported files",
          url: "/exported-files",
        },
      ];
    },
    beta_items: () => [
      {
        title: "Contacts",
        icon: "mdi-account-group",
        url: "/contacts",
      },

      {
        title: "Broadcasts",
        icon: "mdi-bullhorn-outline",
        url: "/broadcasts",
      },
      { title: "Groups", icon: "mdi-group", url: "/broadcasts-groups" },
    ],
  },
};
</script>

<style scoped lang="scss">
.v-list--dense .v-list-item {
  .v-list-item__icon {
    margin-right: 8px;
  }
  .v-badge {
    top: -8px;
    right: 10px;
  }
}
</style>
