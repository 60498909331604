<template>
  <div>
    <header-component :toggleDrawer="() => (drawer = !drawer)" />
    <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <aside-menu />
    </v-navigation-drawer>
    <v-sheet>
      <router-view />
    </v-sheet>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState } from "vuex";
import AsideMenu from "../components/AsideMenu";
import HeaderComponent from "../components/Header";
import { SET_FILTER } from "../store/modules/page/types";
import { buildFilterString } from "../services/services";

export default {
  name: "AuthPage",
  components: { HeaderComponent, AsideMenu },
  data: () => ({
    drawer: false,
    group: null,
  }),

  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      default_filter: (state) => state.page.default_filter,
      filter: (state) => state.page.filter,
    }),
  },

  methods: {
    ...mapMutations({
      setPage: `page/${SET_FILTER}`,
    }),
    changePage: function () {
      let filter = this.$route.query;

      this.setPage({
        filter: { ...filter },
      });
    },

    connectWS: function () {
      // eslint-disable-next-line no-prototype-builtins
      let _this = this;
      let user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        if (user.token) {
          let Socket = new WebSocket(
            `wss://holysms.com/ws/?token=${user.token}`
          );
          Socket.onopen = function () {
            console.log("connected");
          };
          Socket.onmessage = (e) => {
            let data = JSON.parse(e.data);
            console.log(data);
            if (data.message && data.message.message) {
              if (data.message.type) {
                data.message.type === "success"
                  ? _this.$toaster.success(data.message.message)
                  : _this.$toaster.error(data.message.message);
              } else {
                _this.$toaster.info(data.message.message);
              }
            }
          };
          Socket.onclose = (e) => {
            console.log(
              "Socket is closed. Reconnect will be attempted in 2 second.",
              e.reason
            );
            setTimeout(() => {
              this.connectWS();
            }, 2000);
          };
        }
      }
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
    $route(newValue, oldValue) {
      if (newValue.name !== oldValue.name) {
        this.changePage();
      }
    },
    filter(newValue) {
      //обновляю query толко когда filter не равно query и filter не задан по умолчанию
      if (
        !this.default_filter &&
        buildFilterString(newValue) !== buildFilterString(this.$route.query)
      ) {
        this.$router.push({ query: newValue });
      }
    },
  },

  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
    this.connectWS();
  },

  created: function () {
    this.changePage();
  },
};
</script>

<style scoped></style>
